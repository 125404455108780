import React from 'react';

const QuestionDisplay = ({question, index}) => {
  // Helper function to render different question types
  const renderQuestion = () => {
    switch (question.type) {
      case 'mcq':
        return (
          <>
            <div className="flex items-start mb-3">
              <span className="mr-2 font-bold">{index + 1}.</span>
              <span className="font-semibold">{question.question}</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-3 pl-6">
              {Object.entries(question.options).map(([key, value]) => (
                <div key={key} className="flex items-center">
                  <span className="mr-2 w-4">{key})</span>
                  <span>{value}</span>
                </div>
              ))}
            </div>
          </>
        );

      case 'fillInBlanks':
        return (
          <>
            <div className="flex items-start mb-3">
              <span className="mr-2 font-bold">{index + 1}.</span>
              <span className="font-semibold">{question.question}</span>
            </div>
            <div className="pl-6">
              {/* Render underlines for each blank */}
              <div className="space-x-2">
                {Array(question.blanksCount || 1).fill(0).map((_, i) => (
                  <span key={i} className="inline-block w-24 border-b-2 border-gray-400"></span>
                ))}
              </div>
            </div>
          </>
        );

      case 'trueOrFalse':
        return (
          <>
            <div className="flex items-start mb-3">
              <span className="mr-2 font-bold">{index + 1}.</span>
              <span className="font-semibold">{question.statement}</span>
            </div>
            <div className="pl-6 space-x-6">
              <label className="inline-flex items-center">
                <input type="radio" name={`q${index}`} className="form-radio h-4 w-4 text-blue-600"/>
                <span className="ml-2">True</span>
              </label>
              <label className="inline-flex items-center">
                <input type="radio" name={`q${index}`} className="form-radio h-4 w-4 text-blue-600"/>
                <span className="ml-2">False</span>
              </label>
            </div>
          </>
        );

      case 'openEnded':
        return (
          <>
            <div className="flex items-start mb-3">
              <span className="mr-2 font-bold">{index + 1}.</span>
              <span className="font-semibold">{question.question}</span>
            </div>
            <div className="pl-6">
              {/* Lines for writing answer */}
              <div className="border-b border-gray-300 min-h-[100px]"></div>
            </div>
          </>
        );

      default:
        return (
          <div className="flex items-start mb-3">
            <span className="mr-2 font-bold">{index + 1}.</span>
            <span className="font-semibold">{question.question}</span>
          </div>
        );
    }
  };

  return (
    <div className="mb-6 question-container">
      {renderQuestion()}
    </div>
  );
};

export default QuestionDisplay;
