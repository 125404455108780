import React from 'react';
import { useClerk, useUser } from "@clerk/clerk-react";
import { Loader2 } from 'lucide-react';
import WorksheetForm from './WorksheetForm';
import ColoringPageGenerator from './ColoringPageGenerator';

const HeroSection = ({ activeTab, onTabChange, onGenerateWorksheet }) => {
  const { isSignedIn } = useUser();
  const { openSignIn } = useClerk();

  const handleAuthRequired = () => {
    openSignIn();
  };

  const getTextContent = () => {
    if (activeTab === 'text') {
      return {
        title: "Free Printable Worksheets Generator",
        subtitle: isSignedIn 
          ? "Create custom worksheets for your classroom"
          : "Sign up to create unlimited custom worksheets for free"
      };
    }
    return {
      title: "Free Printable Coloring Pages Generator",
      subtitle: isSignedIn
        ? "Create custom coloring pages for kids"
        : "Sign up to create unlimited coloring pages for free"
    };
  };

  const { title, subtitle } = getTextContent();

  // Creating styles for the shimmer effect
  const shimmerStyle = `
    before:content-['NEW']
    before:absolute
    before:-top-2
    before:-right-2
    before:bg-gradient-to-r
    before:from-pink-500
    before:via-purple-500
    before:to-pink-500
    before:bg-[length:200%_100%]
    before:animate-shimmer
    before:px-2
    before:py-1
    before:rounded-md
    before:text-xs
    before:font-bold
    before:text-white
    before:shadow-md
  `;

  return (
    <div className="max-w-xl mx-auto">
      <div className="text-center mb-8">
        <h1 className="text-2xl md:text-3xl font-semibold mb-2">
          {title}
        </h1>
        <p className="text-gray-600">
          {subtitle}
        </p>
      </div>

      <div className="flex justify-center gap-4 mb-8">
        <button
          onClick={() => onTabChange('text')}
          className={`py-3 px-6 rounded-xl text-sm font-medium transition-colors ${
            activeTab === 'text'
              ? 'bg-[#191919] text-white'
              : 'bg-white text-gray-800 border border-[#E5E5E5]'
          }`}
        >
          Text worksheets
        </button>
        <div className="relative">
          <button
            onClick={() => onTabChange('coloring')}
            className={`py-3 px-6 rounded-xl text-sm font-medium transition-colors ${
              activeTab === 'coloring'
                ? 'bg-[#191919] text-white'
                : 'bg-white text-gray-800 border border-[#E5E5E5]'
            } ${shimmerStyle}`}
          >
            Coloring pages
          </button>
        </div>
      </div>

      {activeTab === 'text' ? (
        <div className="rounded-2xl border border-[#E5E5E5] bg-white shadow-[0px_0px_0px_5px_rgba(208,208,208,0.13)] p-8">
          <WorksheetForm 
            onSubmit={onGenerateWorksheet} 
            isAuthenticated={isSignedIn}
            onAuthRequired={handleAuthRequired}
          />
        </div>
      ) : (
        <ColoringPageGenerator 
          isAuthenticated={isSignedIn}
          onAuthRequired={handleAuthRequired}
        />
      )}

      {!isSignedIn && (
        <div className="mt-6 text-center">
          <p className="text-sm text-gray-600">
            Already have an account?{' '}
            <button 
              onClick={handleAuthRequired}
              className="text-[#6B65E0] hover:underline"
            >
              Sign in
            </button>
          </p>
        </div>
      )}
    </div>
  );
};

export default HeroSection;