// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {ClerkProvider} from "@clerk/clerk-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ClerkProvider
    publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}
    afterSignInUrl="/"
    afterSignOutUrl="/"
  >
    <GoogleOAuthProvider clientId="239805480345-4761g1laga76uh6h4cghc2t7gqpnnsec.apps.googleusercontent.com">
      <App/>
    </GoogleOAuthProvider>
  </ClerkProvider>
);

reportWebVitals();
