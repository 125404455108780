import React from 'react';
import {Link} from 'react-router-dom';
import {FaTwitter} from 'react-icons/fa';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <header className="bg-white border-b border-gray-200">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <span className="ml-2 text-xl font-semibold">getWorksheet</span>
          </Link>
          <nav>
            <Link
              to="/worksheet-generator"
              className="px-4 py-2 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Create worksheet
            </Link>
          </nav>
        </div>
      </header>

      <div className="flex-grow bg-[#FAFFFB] py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto bg-white shadow-sm rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold text-gray-900 mb-2">Privacy Policy</h1>
            <p className="text-sm text-gray-600 mb-6">Last updated: 26 Aug, 2024</p>

            <div className="space-y-6">
              <section>
                <h2 className="text-2xl font-semibold text-gray-900 mb-3">1. Introduction</h2>
                <p className="text-gray-700">Welcome to GetWorksheet.co ("we", "us", or "our"). We respect your privacy
                  and are committed to protecting your personal data. This privacy policy will inform you about how we
                  look after your personal data when you visit our website and tell you about your privacy rights and
                  how the law protects you.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-gray-900 mb-3">2. The Data We Collect</h2>
                <p className="text-gray-700 mb-2">We may collect, use, store and transfer different kinds of personal
                  data about you, including:</p>
                <ul className="list-disc pl-5 text-gray-700 space-y-1">
                  <li>Identity Data: first name, last name, username or similar identifier</li>
                  <li>Contact Data: email address and telephone numbers</li>
                  <li>Technical Data: internet protocol (IP) address, browser type and version, time zone setting and
                    location, browser plug-in types and versions, operating system and platform
                  </li>
                  <li>Usage Data: information about how you use our website and services</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-gray-900 mb-3">3. How We Use Your Data</h2>
                <p className="text-gray-700 mb-2">We will only use your personal data when the law allows us to. Most
                  commonly, we will use your personal data in the following circumstances:</p>
                <ul className="list-disc pl-5 text-gray-700 space-y-1">
                  <li>To provide and maintain our service</li>
                  <li>To notify you about changes to our service</li>
                  <li>To provide customer support</li>
                  <li>To gather analysis or valuable information so that we can improve our service</li>
                  <li>To detect, prevent and address technical issues</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-gray-900 mb-3">4. Data Security</h2>
                <p className="text-gray-700">We have put in place appropriate security measures to prevent your personal
                  data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-gray-900 mb-3">5. Data Retention</h2>
                <p className="text-gray-700">We will only retain your personal data for as long as necessary to fulfill
                  the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or
                  reporting requirements.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-gray-900 mb-3">6. Your Legal Rights</h2>
                <p className="text-gray-700">Under certain circumstances, you have rights under data protection laws in
                  relation to your personal data, including the right to request access, correction, erasure,
                  restriction, transfer, or to object to processing.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-gray-900 mb-3">7. Third-Party Links</h2>
                <p className="text-gray-700">Our website may include links to third-party websites, plug-ins and
                  applications. Clicking on those links or enabling those connections may allow third parties to collect
                  or share data about you. We do not control these third-party websites and are not responsible for
                  their privacy statements.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-gray-900 mb-3">8. Changes to This Privacy Policy</h2>
                <p className="text-gray-700">We may update our Privacy Policy from time to time. We will notify you of
                  any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the
                  top of this Privacy Policy.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-gray-900 mb-3">9. Contact Us</h2>
                <p className="text-gray-700">If you have any questions about this Privacy Policy, please contact us
                  at: <a href="mailto:getworksheet@gmail.com"
                         className="text-blue-600 hover:underline">getworksheet@gmail.com</a></p>
              </section>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-gray-900 text-white">
        <div className="container mx-auto px-4 py-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">About us</h3>
              <p className="text-gray-400">
                Empowering educators with easy-to-use tools for creating engaging worksheets.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Quick links</h3>
              <ul className="space-y-2">
                <li><Link to="/about" className="text-gray-400 hover:text-white transition duration-300">About</Link>
                </li>
                <li><Link to="/contact"
                          className="text-gray-400 hover:text-white transition duration-300">Contact</Link></li>
                <li><Link to="/privacy-policy" className="text-gray-400 hover:text-white transition duration-300">Privacy
                  policy</Link></li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Connect with us</h3>
              <div className="flex space-x-4">
                <a href="https://x.com/worksheet23194"
                   className="text-gray-400 hover:text-white transition duration-300" target="_blank"
                   rel="noopener noreferrer">
                  <FaTwitter size={24}/>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
            <p>&copy; 2024 Worksheet Generator. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
