import React from 'react';
import { SignIn } from "@clerk/clerk-react";

function LoginPage() {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <SignIn routing="path" path="/login" />
    </div>
  );
}

export default LoginPage;
