import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import HomePage from './pages/HomePage';
import WorksheetsPage from './pages/WorksheetsPage';
import SubjectPage from './pages/SubjectPage';
import WorksheetCollectionPage from './pages/WorksheetCollectionPage';
import WorksheetGenerator from './pages/WorksheetGenerator';
import {initAnalytics, trackPageView} from './analytics';
import PrivacyPolicy from './components/PrivacyPolicy';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import ProtectedRoute from "./ProtectedRoute";
import LoginPage from "./pages/LoginPage";

// Create a new component to handle analytics

function AnalyticsWrapper() {
  const location = useLocation();

  useEffect(() => {
    initAnalytics();
  }, []);

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      <AnalyticsWrapper/>
      <Routes>
        <Route path="/login" element={<LoginPage/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/contact" element={<ContactUs/>}/>
        <Route path="/about" element={<AboutUs/>}/>

        <Route
          path="/"
          element={<HomePage/>}
        />
        <Route
          path="/worksheets"
          element={
            <WorksheetsPage/>
          }
        />
        <Route
          path="/worksheets/:subjectId"
          element={
            <SubjectPage/>
          }
        />
        <Route
          path="/worksheets/:subjectId/:collectionId"
          element={
            <WorksheetCollectionPage/>
          }
        />
        <Route
          path="/worksheet-generator"
          element={
            <ProtectedRoute>
              <WorksheetGenerator/>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
