import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export const generateColoringImage = async (prompt) => {
  try {
    const enhancedPrompt = `A professional black and white line art illustration suitable for a coloring book, featuring ${prompt}. Use clean, bold outlines, no grayscale shading, and clear spaces for coloring. Make it simple yet engaging, with well-defined areas for coloring. Only use black lines on white background.`;

    const response = await openai.images.generate({
      model: "dall-e-3",
      prompt: enhancedPrompt,
      n: 1,
      size: "1024x1024",
      quality: "standard",
      response_format: "b64_json" // Changed to base64
    });

    // Convert base64 to URL
    const imageData = response.data[0].b64_json;
    const imageUrl = `data:image/png;base64,${imageData}`;
    return imageUrl;
  } catch (error) {
    console.error('Error generating coloring page:', error);
    throw error;
  }
};