import React from 'react';
import {Link} from 'react-router-dom';
import {FaTwitter} from 'react-icons/fa';

function AboutUs() {
  return (
    <div className="flex flex-col min-h-screen">
      <header className="bg-white border-b border-gray-200">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <span className="ml-2 text-xl font-semibold">getWorksheet</span>
          </Link>
          <nav>
            <Link
              to="/worksheet-generator"
              className="px-4 py-2 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Create worksheet
            </Link>
          </nav>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 py-8 max-w-3xl">
        <h1 className="text-3xl font-bold mb-6">About Us</h1>
        <p className="text-gray-600 mb-4">Last updated: 26 Aug, 2024</p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
          <p className="text-gray-700 mb-4">We're on a mission to make learning fun and easy. At GetWorksheet, we
            believe every child has the potential to love learning—sometimes, they just need the right tools.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Who We Are</h2>
          <p className="text-gray-700 mb-4">We're the team behind GetWorksheet: a small group of teachers, parents, and
            tech enthusiasts who got together over coffee (lots of coffee) and asked, "What if worksheets could be
            awesome?"</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">What We Do</h2>
          <p className="text-gray-700 mb-4">We create worksheets that kids actually want to do. Our materials cover
            everything from ABCs to 123s, designed to:</p>
          <ul className="list-disc pl-5 mb-4 text-gray-700">
            <li>Spark curiosity</li>
            <li>Make learning feel like play</li>
            <li>Turn yawns into "aha!" moments</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Who We Serve</h2>
          <ul className="list-disc pl-5 mb-4 text-gray-700">
            <li>Teachers looking for engaging classroom materials</li>
            <li>Parents wanting to give their kids a little extra boost</li>
            <li>Any child ready for a learning adventure</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Vision</h2>
          <p className="text-gray-700 mb-4">Remember how boring worksheets used to be? We decided to change that.
            GetWorksheet was born from the idea that learning should be an exciting journey.</p>
        </section>

        <hr className="my-8 border-gray-300"/>

        <p className="text-gray-700">Welcome to GetWorksheet! We're glad you're here. Let's make learning an adventure,
          one worksheet at a time.</p>
      </main>

      <footer className="bg-gray-900 text-white">
        <div className="container mx-auto px-4 py-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">About us</h3>
              <p className="text-gray-400">
                Empowering educators with easy-to-use tools for creating engaging worksheets.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Quick links</h3>
              <ul className="space-y-2">
                <li><Link to="/about" className="text-gray-400 hover:text-white transition duration-300">About</Link>
                </li>
                <li><Link to="/contact"
                          className="text-gray-400 hover:text-white transition duration-300">Contact</Link></li>
                <li><Link to="/privacy-policy" className="text-gray-400 hover:text-white transition duration-300">Privacy
                  policy</Link></li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Connect with us</h3>
              <div className="flex space-x-4">
                <a href="https://x.com/worksheet23194"
                   className="text-gray-400 hover:text-white transition duration-300" target="_blank"
                   rel="noopener noreferrer">
                  <FaTwitter size={24}/>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
            <p>&copy; 2024 Worksheet Generator. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default AboutUs;
