import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../components/Layout';
import SEO from '../config/seo';
import axios from 'axios';
import {ArrowDownToLine} from 'lucide-react';
import {trackEvent} from '../analytics';

const SUBJECTS_SPREADSHEET_ID = '1Ar7X_3tDUI0v27VAWwVPT1QusBQaE1EE2i9rZajAvw8';
const API_KEY = 'AIzaSyA0nuOUtyIOA4bvZZZO6E9-zoZnGHLJHmk';

const WorksheetsPage = () => {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSubjects = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${SUBJECTS_SPREADSHEET_ID}/values/Sheet1!A2:D?key=${API_KEY}`);
      setSubjects(response.data.values.map(([id, name, description, thumbnail]) => ({
        id, name, description, thumbnail
      })));
      setLoading(false);
    } catch (err) {
      console.error('Error fetching subjects:', err);
      setError('Failed to load subjects. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  const handleKeyPress = useCallback((event) => {
    // Secret key combination: Ctrl + Shift + R
    if (event.ctrlKey && event.shiftKey && event.key === 'R') {
      event.preventDefault();
      fetchSubjects();
      console.log('Data refreshed');
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  // Prepare SEO data
  const seoTitle = "Free Printable Worksheets for All Subjects | Get Worksheet";
  const seoDescription = "Browse our collection of free printable worksheets for various subjects. Find high-quality educational materials for students of all ages.";
  const seoImage = "https://getworksheet.co/Default.png";
  const seoUrl = "https://getworksheet.co/worksheets";

  const handleBundleClick = () => {
    trackEvent('worksheet-bundle-clicked', {
      page: 'worksheets_page',
      source: 'page_banner'
    });
    window.open('https://worksheetmaster3.gumroad.com/l/worksheet-bundle', '_blank');
  };

  if (loading) return <Layout><SEO title={seoTitle} description={seoDescription} image={seoImage} url={seoUrl}/>
    <div>Loading subjects...</div>
  </Layout>;
  if (error) return <Layout><SEO title={seoTitle} description={seoDescription} image={seoImage} url={seoUrl}/>
    <div>Error: {error}</div>
  </Layout>;

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        url={seoUrl}
      />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">All Subjects</h1>

        {/* Bundle offer component */}
        <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-4 rounded-lg mb-8">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <p className="text-lg font-semibold mb-2 sm:mb-0">Get 88 worksheets bundle for just $2 - Limited time</p>
            <button
              onClick={handleBundleClick}
              className="bg-white text-blue-600 px-4 py-2 rounded-lg hover:bg-gray-100 transition-colors duration-300 flex items-center"
            >
              <ArrowDownToLine className="w-5 h-5 mr-2"/>
              Get Bundle
            </button>
          </div>
        </div>

        {subjects.length === 0 ? (
          <p>No subjects found.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {subjects.map(subject => (
              <Link key={subject.id} to={`/worksheets/${subject.id}`} className="block">
                <div className="border p-4 rounded hover:shadow-lg transition-shadow duration-300">
                  {subject.thumbnail && (
                    <img
                      src={subject.thumbnail}
                      alt={subject.name}
                      className="w-full h-40 object-cover mb-4 rounded"
                    />
                  )}
                  <h3 className="text-xl font-semibold mb-2">{subject.name}</h3>
                  <p className="text-gray-600">{subject.description}</p>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default WorksheetsPage;
