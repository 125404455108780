import React from 'react';

const TextToParagraphs = ({text, className}) => {
  // Split the text by double newlines or single newlines
  const paragraphs = text.split(/\n\n|\n/).filter(p => p.trim() !== '');

  return (
    <div className={className}>
      {paragraphs.map((paragraph, index) => (
        <p key={index} className="mb-4 last:mb-0">{paragraph}</p>
      ))}
    </div>
  );
};

export default TextToParagraphs;
