import React from 'react';
import { Zap, Lock, MessageCircle } from 'lucide-react';

const WhyChooseSection = () => {
  // Define the features with their respective icons and colors
  const features = [
    {
      icon: Zap,
      iconBgColor: 'bg-[#FFD700]', // Yellow background for speed icon
      title: 'Super-fast',
      description: 'Create custom content in minutes, not hours.'
    },
    {
      icon: Lock,
      iconBgColor: 'bg-[#FF7F50]', // Coral background for security icon
      title: 'Private & Secure',
      description: 'We do not snoop and we do not sell your data'
    },
    {
      icon: MessageCircle,
      iconBgColor: 'bg-[#FFD700]', // Yellow background for support icon
      title: 'Fast Support',
      description: 'Need help? Chat with us quickly within the app'
    }
  ];

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl font-semibold mb-8 text-center">
        Why choose our generator?
      </h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {features.map((feature, index) => {
          const Icon = feature.icon;
          
          return (
            <div 
              key={index} 
              className="bg-white p-8 rounded-lg shadow-sm text-left border border-ng-100"
            >
              {/* Icon Container */}
              <div className={`w-12 h-12 rounded-full ${feature.iconBgColor} flex items-center justify-center mb-6`}>
                <Icon className="w-6 h-6 text-white" />
              </div>
              
              {/* Content */}
              <h3 className="text-xl font-semibold mb-3">
                {feature.title}
              </h3>
              <p className="text-gray-600">
                {feature.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WhyChooseSection;