import React from 'react';
import {Link} from 'react-router-dom';

const InterlinkSection = () => {
  const worksheetCategories = [
    {
      name: "Mathematics",
      worksheets: [
        {title: "Circle Tracing", url: "/worksheets/maths/circle-tracing-worksheet"},
        {title: "Triangle Tracing", url: "/worksheets/maths/triangle-tracing-worksheet"},
        {title: "Square Tracing", url: "/worksheets/maths/square-tracing-worksheet"},
        {title: "Missing Number", url: "/worksheets/maths/missing-number-worksheet"},
        {title: "Multi-Digit Multiplication", url: "/worksheets/maths/multi-digit-multiplication-worksheets"},
        {title: "Addition", url: "/worksheets/maths/addition-worksheet"},
        {title: "Doubles Addition", url: "/worksheets/maths/doubles-addition-worksheet"},
        {title: "Subtraction", url: "/worksheets/maths/subtraction-worksheet"},
        {title: "3D Shapes", url: "/worksheets/maths/3D-shapes-worksheets"},
        {title: "Decimal Division", url: "/worksheets/maths/decimal-division-worksheet"},
        {title: "Pythagorean Theorem", url: "/worksheets/maths/pythagorean-theorem-worksheet"},
        {title: "Two-Step Equations", url: "/worksheets/maths/two-step-equations-worksheet"},
        {title: "Shapes Flashcards", url: "/worksheets/maths/shapes-flashcards"},
        {title: "Number Flashcards", url: "/worksheets/maths/number-flashcards"},
        {title: "Before and After Numbers", url: "/worksheets/maths/before-and-after-numbers"},
        {title: "Multiplication", url: "/worksheets/maths/multiplication-worksheets"},
        {title: "Count and Color", url: "/worksheets/maths/count-and-color-worksheets"},
        {title: "Repeating Patterns", url: "/worksheets/maths/repeating-patterns-worksheets"},
        {title: "Montessori Beads", url: "/worksheets/maths/montessori-beads-worksheets"},
        {title: "Prime Numbers", url: "/worksheets/maths/prime-number-worksheets"},
        {title: "Composite Numbers", url: "/worksheets/maths/composite-number-worksheets"}
      ]
    },
    {
      name: "Arts and Crafts",
      worksheets: [
        {title: "Number Coloring Pages", url: "/worksheets/arts/number-coloring-pages"},
        {title: "Animal Coloring Pages", url: "/worksheets/arts/animal-coloring-pages"},
        {title: "Vehicle Coloring Pages", url: "/worksheets/arts/vehicle-coloring-pages"},
        {title: "Connect the Dots", url: "/worksheets/arts/connect-the-dots-printable"},
        {title: "Merry Christmas Coloring Pages", url: "/worksheets/arts/merry-christmas-coloring-pages"},
        {title: "Fire Truck Coloring Page", url: "/worksheets/arts/fire-truck-coloring-page-printable"},
        {title: "Winter Coloring Pages", url: "/worksheets/arts/winter-coloring-pages"},
        {title: "Summer Coloring Pages", url: "/worksheets/arts/summer-coloring-pages"},
        {title: "Spring Coloring Pages", url: "/worksheets/arts/spring-coloring-pages"},
        {title: "Leprechaun Coloring Page", url: "/worksheets/arts/leprechaun-coloring-page"},
        {title: "Copy the Picture", url: "/worksheets/arts/copy-the-picture-worksheets"}
      ]
    },
    {
      name: "Life Skills",
      worksheets: [
        {title: "All About Me", url: "/worksheets/life-skills/all-about-me-worksheet"},
        {title: "Back to School", url: "/worksheets/life-skills/back-to-school-worksheet"},
        {title: "Potty Training Chart", url: "/worksheets/life-skills/potty-training-chart-printable"},
        {title: "First Day of School Sign", url: "/worksheets/life-skills/first-day-of-school-sign-printable"},
        {title: "I Spy Worksheets", url: "/worksheets/life-skills/i-spy-worksheets"}
      ]
    },
    {
      name: "English",
      worksheets: [
        {title: "Letter Worksheets", url: "/worksheets/english/letter-worksheets"},
        {title: "Spelling Worksheets", url: "/worksheets/english/spelling-worksheets"},
        {title: "Phonics Worksheets", url: "/worksheets/english/phonics-worksheets"},
        {title: "Persuasive Writing", url: "/worksheets/english/persuasive-writing-worksheets"}
      ]
    },
    {
      name: "Chemistry",
      worksheets: [
        {title: "Balancing Equations", url: "/worksheets/chemistry/balancing-equations-worksheet"}
      ]
    }
  ];

  return (
    <section className="mt-12 bg-gray-100 p-6 rounded-lg">
      <h2 className="text-2xl font-bold mb-6">Explore More Worksheets</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {worksheetCategories.map((category, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-3">{category.name}</h3>
            <ul className="space-y-2">
              {category.worksheets.map((worksheet, wIndex) => (
                <li key={wIndex}>
                  <Link
                    to={worksheet.url}
                    className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
                  >
                    {worksheet.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default InterlinkSection;
