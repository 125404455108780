import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Layout from '../components/Layout';
import SEO from '../config/seo';
import WorksheetForm from '../components/WorksheetForm';
import EditWorksheet from '../components/EditWorksheet';
import QuestionDisplay from '../components/QuestionDisplay';
import AnswerKeyDisplay from '../components/AnswerKeyDisplay';
import ColoringPageGenerator from '../components/ColoringPageGenerator';
import {generateWorksheet, generateWorksheetBatch, uploadToCloudinary} from '../config/api';
import html2pdf from 'html2pdf.js';
import {Loader2} from 'lucide-react';
import {trackEvent, trackPageView} from '../analytics';
import CustomGoogleAd from '../components/CustomGoogleAd';
import { useUser, useClerk } from '@clerk/clerk-react';


const WorksheetGenerator = () => {
  const { isSignedIn } = useUser();
  const { openSignIn } = useClerk();


  const handleAuthRequired = () => {
    openSignIn();
  };

    // Tab state
    const [activeTab, setActiveTab] = useState('text');


  // Existing states
  const location = useLocation();
  const [worksheetData, setWorksheetData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(location.state?.formData || null);
  const [worksheetTitle, setWorksheetTitle] = useState('');
  const [fontSize, setFontSize] = useState(16);
  const [includeTitle, setIncludeTitle] = useState(true);
  const [includeNameDate, setIncludeNameDate] = useState(true);
  const [generationProgress, setGenerationProgress] = useState(0);

  // Refs
  const worksheetRef = useRef(null);
  const answerKeyRef = useRef(null);

  // Track page view
  useEffect(() => {
    trackPageView("/worksheet-generator");
    console.log("checking 1");
  }, []);

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    trackEvent("tab_changed", {tab});
  };

  // Main worksheet generation function
  const handleGenerateWorksheet = useCallback(async (data) => {
    console.log('Generating worksheet with data:', data);
    setLoading(true);
    setError(null);
    setGenerationProgress(0);
    const startTime = Date.now();

    try {
      let result;
      const totalQuestions = data.numberOfQuestions;

      if (totalQuestions <= 8) {
        result = await generateWorksheet(data);
      } else {
        let allQuestions = [];
        let answerKey = {};
        const batchSize = 5;

        for (let i = 0; i < totalQuestions; i += batchSize) {
          const batch = await generateWorksheetBatch(data, i, Math.min(batchSize, totalQuestions - i));
          allQuestions = [...allQuestions, ...batch.questions];
          answerKey = {...answerKey, ...batch.answerKey};
          setGenerationProgress(Math.min(100, (i + batchSize) / totalQuestions * 100));
        }

        result = {
          title: `${data.gradeLevel} ${data.topic} Worksheet`,
          questions: allQuestions,
          answerKey: answerKey,
        };
      }

      if (!result.questions || !Array.isArray(result.questions)) {
        throw new Error('Invalid worksheet data: questions array is missing or not an array');
      }

      // Process questions based on their type
      result.questions = result.questions.map(question => {
        switch (question.type) {
          case 'mcq':
            return {
              ...question,
              options: question.options || {}
            };
          case 'fillInBlanks':
            return {
              ...question,
              blanksCount: (question.question.match(/___/g) || []).length
            };
          case 'trueOrFalse':
            return {
              ...question,
              statement: question.statement || question.question
            };
          case 'openEnded':
            return {
              ...question,
              keyPoints: question.keyPoints || []
            };
          default:
            return {
              ...question,
              options: question.options || {}
            };
        }
      });

      result.answerKey = typeof result.answerKey === 'object' ? result.answerKey : {};

      setWorksheetData(result);
      setWorksheetTitle(result.title || 'Untitled Worksheet');

      const duration = Date.now() - startTime;
      trackEvent("worksheet_generated", {
        title: result.title,
        duration: duration,
        questionTypes: data.questionTypes,
        ...data
      });
    } catch (err) {
      console.error('Error generating worksheet:', err);
      setError('Failed to generate worksheet. Please try again.');
      trackEvent("worksheet_generation_error", {
        error: err.message,
        ...data
      });
    } finally {
      setLoading(false);
      setGenerationProgress(0);
    }
  }, []);

  useEffect(() => {
    if (formData) {
      handleGenerateWorksheet(formData);
    }
  }, [formData, handleGenerateWorksheet]);


  const generatePdf = useCallback((element, filename) => {
    console.log(`Starting PDF generation process for ${filename}...`);
    return new Promise((resolve, reject) => {
      if (!element) {
        console.error('Element not found');
        reject(new Error('Element not found'));
        return;
      }

      const opt = {
        margin: [20, 20, 40, 20],
        filename: filename,
        image: {type: 'jpeg', quality: 0.98},
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          windowHeight: element.scrollHeight + 40
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
          compress: true,
          hotfixes: ['px_scaling']
        },
        pagebreak: {
          mode: ['avoid-all', 'css', 'legacy'],
          before: '.page-break',
          avoid: ['.answer-item', '.answer-content']
        }
      };

      const originalPadding = element.style.paddingBottom;
      element.style.paddingBottom = '60px';

      html2pdf()
        .set(opt)
        .from(element)
        .outputPdf('blob')
        .then((pdfBlob) => {
          element.style.paddingBottom = originalPadding;
          console.log('PDF generation completed successfully');
          resolve(pdfBlob);
        })
        .catch(error => {
          element.style.paddingBottom = originalPadding;
          console.error('Error in html2pdf process:', error);
          reject(error);
        });
    });
  }, []);

  const saveToCloudinary = useCallback(async (pdfBlob, title) => {
    console.log('Starting to save worksheet to Cloudinary...');
    try {
      const result = await uploadToCloudinary(pdfBlob, title);
      console.log('File successfully saved to Cloudinary. File details:', result);
      trackEvent("cloudinary_upload_success", {title: title, url: result.url});
    } catch (error) {
      console.error('Error saving to Cloudinary:', error);
      trackEvent("cloudinary_upload_error", {title: title, error: error.message});
    }
  }, []);

  const downloadPdf = useCallback((pdfBlob, filename) => {
    const url = URL.createObjectURL(pdfBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, []);

  const handleDownloadWorksheet = useCallback(async () => {
    console.log('Starting worksheet download process...');
    try {
      const pdfBlob = await generatePdf(worksheetRef.current, `${worksheetTitle}.pdf`);
      downloadPdf(pdfBlob, `${worksheetTitle}.pdf`);
      trackEvent("worksheet_pdf_downloaded", {title: worksheetTitle});

      saveToCloudinary(pdfBlob, worksheetTitle).catch(console.error);
    } catch (error) {
      console.error('Error in worksheet download process:', error);
      setError('Failed to download worksheet. Please try again.');
      trackEvent("worksheet_download_error", {title: worksheetTitle, error: error.message});
    }
  }, [generatePdf, downloadPdf, saveToCloudinary, worksheetTitle]);

  const handleDownloadAnswerKey = useCallback(async () => {
    console.log('Starting answer key download process...');
    try {
      const pdfBlob = await generatePdf(answerKeyRef.current, `${worksheetTitle}_AnswerKey.pdf`);
      downloadPdf(pdfBlob, `${worksheetTitle}_AnswerKey.pdf`);
      trackEvent("answer_key_pdf_downloaded", {title: worksheetTitle});
    } catch (error) {
      console.error('Error in answer key download process:', error);
      setError('Failed to download answer key. Please try again.');
      trackEvent("answer_key_download_error", {title: worksheetTitle, error: error.message});
    }
  }, [generatePdf, downloadPdf, worksheetTitle]);

  const handleFormSubmit = useCallback((newFormData) => {
    console.log('Form submitted with data:', newFormData);
    setFormData(newFormData);
    handleGenerateWorksheet(newFormData);
    trackEvent("worksheet_form_submitted", newFormData);
  }, [handleGenerateWorksheet]);

  const handleTitleChange = useCallback((newTitle) => {
    setWorksheetTitle(newTitle);
    trackEvent("worksheet_title_changed", {newTitle: newTitle});
  }, []);

  const handleFontSizeChange = useCallback((change) => {
    setFontSize(prevSize => {
      const newSize = Math.max(12, Math.min(24, prevSize + change));
      trackEvent("font_size_changed", {newSize: newSize, change: change});
      return newSize;
    });
  }, []);

  const handleIncludeTitleChange = useCallback((checked) => {
    setIncludeTitle(checked);
    trackEvent("include_title_changed", {included: checked});
  }, []);

  const handleIncludeNameDateChange = useCallback((checked) => {
    setIncludeNameDate(checked);
    trackEvent("include_name_date_changed", {included: checked});
  }, []);

  return (
    <Layout>
      <SEO
        title="Custom Worksheet Generator | Get Worksheet"
        description="Create custom worksheets and coloring pages with our free generator."
        image="https://getworksheet.co/Worksheet-Generator.png"
        url="https://getworksheet.co/worksheet-generator"
      />
      <div className="container mx-auto px-4 py-4 sm:py-8">
        <h1 className="text-2xl sm:text-3xl font-bold mb-6">Worksheet Generator</h1>

        {/* Updated Tab Navigation */}
        <div className="flex justify-start gap-4 mb-8">
          <button
            onClick={() => handleTabChange('text')}
            className={`py-3 px-6 rounded-xl text-sm font-medium transition-colors ${
              activeTab === 'text'
                ? 'bg-[#191919] text-white'
                : 'bg-[#FBFBFB] text-gray-800 border border-[#E5E5E5]'
            }`}
          >
            Text worksheets
          </button>
          <button
            onClick={() => handleTabChange('coloring')}
            className={`py-3 px-6 rounded-xl text-sm font-medium transition-colors ${
              activeTab === 'coloring'
                ? 'bg-[#191919] text-white'
                : 'bg-[#FBFBFB] text-gray-800 border border-[#E5E5E5]'
            }`}
          >
            Coloring pages
          </button>
        </div>

        {/* Tab Content */}
        {activeTab === 'text' ? (
          <div className="flex flex-col lg:flex-row gap-4 sm:gap-8">
            <div className="w-full lg:w-1/3 space-y-4">
              <div
                className="rounded-2xl border border-[#E5E5E5] bg-white shadow-[0px_0px_0px_5px_rgba(208,208,208,0.13)] p-8">
                <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Worksheet Details</h2>
                <WorksheetForm
            onSubmit={handleFormSubmit}
            initialData={formData}
            isLoading={loading}
            isAuthenticated={isSignedIn}  // Use isSignedIn here
            onAuthRequired={handleAuthRequired}
          />
              </div>

              <div className="mt-8">
                <CustomGoogleAd/>
              </div>

              {worksheetData && !loading && (
                <EditWorksheet
                  initialTitle={worksheetTitle}
                  onTitleChange={handleTitleChange}
                  onFontSizeChange={handleFontSizeChange}
                  fontSize={fontSize}
                  includeTitle={includeTitle}
                  onIncludeTitleChange={handleIncludeTitleChange}
                  includeNameDate={includeNameDate}
                  onIncludeNameDateChange={handleIncludeNameDateChange}
                />
              )}
            </div>

            <div className="w-full lg:w-2/3">
              {worksheetData && !loading && (
                <div className="mb-4 flex flex-col sm:flex-row justify-between items-start sm:items-center">
                  <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-0">{worksheetTitle}</h2>
                  <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                    <button
                      onClick={handleDownloadWorksheet}
                      className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 w-full sm:w-auto"
                    >
                      Download Worksheet
                    </button>
                    <button
                      onClick={handleDownloadAnswerKey}
                      className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 w-full sm:w-auto"
                    >
                      Download Answer Key
                    </button>
                  </div>
                </div>
              )}

              {loading && (
                <div className="flex flex-col items-center justify-center h-64">
                  <Loader2 className="w-12 h-12 sm:w-16 sm:h-16 text-blue-500 animate-spin"/>
                  <p className="mt-4 text-base sm:text-lg font-semibold text-gray-700">Generating your worksheet...</p>
                  <p className="mt-2 text-sm text-gray-500">This may take a few moments</p>
                  {generationProgress > 0 && (
                    <>
                      <div className="w-full max-w-md bg-gray-200 rounded-full h-2.5 mt-4">
                        <div
                          className="bg-indigo-600 h-2.5 rounded-full"
                          style={{width: `${generationProgress}%`}}
                        ></div>
                      </div>
                      <p className="mt-2 text-sm text-gray-500">{Math.round(generationProgress)}% complete</p>
                    </>
                  )}
                </div>
              )}

              {error && (
                <div
                  className="rounded-2xl border border-[#E5E5E5] bg-white shadow-[0px_0px_0px_5px_rgba(208,208,208,0.13)] px-4 sm:px-8 py-4 sm:py-6 mb-4">
                  <p className="text-red-500 text-center">{error}</p>
                  <button
                    onClick={() => handleGenerateWorksheet(formData)}
                    className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 w-full sm:w-auto"
                  >
                    Retry
                  </button>
                </div>
              )}

              {worksheetData && !loading && (
                <div
                  className="rounded-2xl border border-[#E5E5E5] bg-white shadow-[0px_0px_0px_5px_rgba(208,208,208,0.13)] px-4 sm:px-8 py-4 sm:py-6 mb-4">
                  <div ref={worksheetRef} style={{fontSize: `${fontSize}px`}} className="overflow-hidden">
                    {includeTitle && <h1 className="text-xl sm:text-2xl font-bold mb-4">{worksheetTitle}</h1>}
                    {includeNameDate && (
                      <div className="flex flex-col sm:flex-row justify-between mb-4">
                        <div className="mb-2 sm:mb-0">Name: ____________________</div>
                        <div>Date: ____________________</div>
                      </div>
                    )}
                    <div className="space-y-8">
                      {worksheetData.questions.map((question, index) => (
                        <QuestionDisplay
                          key={question.id || index}
                          question={question}
                          index={index}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* Hidden answer key for PDF generation */}
              <div className="hidden">
                <div
                  ref={answerKeyRef}
                  style={{fontSize: `${fontSize}px`}}
                  className="overflow-hidden pdf-content p-6"
                >
                  <h1 className="text-xl sm:text-2xl font-bold mb-8">{worksheetTitle} - Answer Key</h1>
                  <div className="space-y-6 answer-key-content">
                    {worksheetData?.questions.map((question, index) => (
                      <AnswerKeyDisplay
                        key={question.id || index}
                        question={question}
                        index={index}
                      />
                    ))}
                  </div>
                  <div className="h-16"></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ColoringPageGenerator
          isAuthenticated={isSignedIn}  // Use isSignedIn here instead
          onAuthRequired={handleAuthRequired}
        />
        )}
      </div>
    </Layout>
  );
};

export default WorksheetGenerator;
