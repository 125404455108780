// pages/HomePage.js
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Layout from '../components/Layout';
import HeroSection from '../components/HeroSection';
import FeaturesSection from '../components/FeaturesSection';
import WhyChooseSection from '../components/WhyChooseSection';
import FeaturedCollections from '../components/FeaturedCollections';
import {trackEvent} from '../analytics';
import SEO from '../config/seo';
import { useUser, useClerk } from '@clerk/clerk-react';

const HomePage = () => {
  const navigate = useNavigate();
  const { isSignedIn } = useUser();
  const { openSignIn } = useClerk();
  const [activeTab, setActiveTab] = useState('text');

  const handleAuthRequired = () => {
    openSignIn();
  };

  const handleGenerateWorksheet = (formData) => {
    navigate('/worksheet-generator', {state: {formData}});
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    trackEvent("tab_changed_home", {tab});
  };

  return (
    <Layout>
      <SEO
        title="Free Printable Worksheets | Get Worksheet"
        description="Download free printable worksheets and coloring pages for various subjects and grade levels."
        image="https://getworksheet.co/Default.png"
        url="https://getworksheet.co"
      />
      <div className="bg-[#FAFFFB]">
        <div className="container mx-auto px-4 py-16 mb-8">
        <HeroSection 
      activeTab={activeTab}
      onTabChange={handleTabChange}
      onGenerateWorksheet={handleGenerateWorksheet}
      isAuthenticated={isSignedIn}
      onAuthRequired={handleAuthRequired}
    />

          {/* Featured Collections */}
          {activeTab === 'text' && (
            <div className="mb-16 mt-16">
              <h2 className="text-3xl font-semibold mb-8 text-center">Get free printable worksheets!</h2>
              <FeaturedCollections/>
            </div>
          )}

          {/* Features Section */}
          <FeaturesSection activeTab={activeTab}/>

          {/* Why Choose Section */}
          <WhyChooseSection/>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
