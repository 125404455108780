import React from 'react';
import {Link} from 'react-router-dom';
import {FaTwitter} from 'react-icons/fa';

const ContactUs = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <header className="bg-white border-b border-gray-200">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <span className="ml-2 text-xl font-semibold">Get Worksheet</span>
          </Link>
          <nav>
            <Link
              to="/worksheet-generator"
              className="px-4 py-2 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Create worksheet
            </Link>
          </nav>
        </div>
      </header>

      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-6">Contact Us</h1>
          <div className="aspect-w-16 aspect-h-9">
            <iframe
              src="https://www.formlite.co/public-form/1FAIpQLSc19CvdhG441O1SfGWgJmkjZ2lQF7kyVfULwiOMHGzoMQZc-g?template=Interactive"
              className="w-full h-[800px] border-0"
              style={{border: 'none'}}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </main>

      <footer className="bg-gray-900 text-white">
        <div className="container mx-auto px-4 py-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">About us</h3>
              <p className="text-gray-400">
                Empowering educators with easy-to-use tools for creating engaging worksheets.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Quick links</h3>
              <ul className="space-y-2">
                <li><Link to="/about" className="text-gray-400 hover:text-white transition duration-300">About</Link>
                </li>
                <li><Link to="/contact"
                          className="text-gray-400 hover:text-white transition duration-300">Contact</Link></li>
                <li><Link to="/privacy-policy" className="text-gray-400 hover:text-white transition duration-300">Privacy
                  policy</Link></li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Connect with us</h3>
              <div className="flex space-x-4">
                <a href="https://x.com/worksheet23194"
                   className="text-gray-400 hover:text-white transition duration-300" target="_blank"
                   rel="noopener noreferrer">
                  <FaTwitter size={24}/>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
            <p>&copy; 2024 Worksheet Generator. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ContactUs;
