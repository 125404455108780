import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://getworksheet-backend.vercel.app';

// Helper function to ensure we don't have double slashes in our URL
const createUrl = (base, path) => {
  return `${base.replace(/\/+$/, '')}/${path.replace(/^\/+/, '')}`;
};

export const generateWorksheet = async (worksheetData) => {
  try {
    const url = createUrl(API_BASE_URL, '/api/generate-worksheet');
    console.log('Sending request to:', url); // For debugging
    const response = await axios.post(url, worksheetData);
    return response.data;
  } catch (error) {
    console.error('Error generating worksheet:', error);
    throw error;
  }
};

export const generateWorksheetBatch = async (worksheetData, startIndex, batchSize) => {
  try {
    const url = createUrl(API_BASE_URL, '/api/generate-worksheet-batch');
    console.log('Sending batch request to:', url); // For debugging
    const response = await axios.post(url, {
      ...worksheetData,
      startIndex,
      batchSize
    });
    return response.data;
  } catch (error) {
    console.error('Error generating worksheet batch:', error);
    throw error;
  }
};

export const uploadToCloudinary = async (pdfBlob, title) => {
  const formData = new FormData();
  formData.append('file', pdfBlob, `${title}.pdf`);
  formData.append('title', title);

  try {
    const url = createUrl(API_BASE_URL, '/api/upload-to-cloudinary');
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error uploading to Cloudinary:', error);
    throw error;
  }
};

// Helper function to determine if batching is needed
export const shouldUseBatching = (numberOfQuestions) => {
  return numberOfQuestions > 8;
};

// Function to handle worksheet generation with or without batching
export const generateWorksheetWithBatching = async (worksheetData) => {
  const {numberOfQuestions} = worksheetData;

  if (!shouldUseBatching(numberOfQuestions)) {
    return generateWorksheet(worksheetData);
  }

  let allQuestions = [];
  let answerKey = {};
  const batchSize = 5; // Adjust this value based on what works best

  for (let i = 0; i < numberOfQuestions; i += batchSize) {
    const batch = await generateWorksheetBatch(
      worksheetData,
      i,
      Math.min(batchSize, numberOfQuestions - i)
    );
    allQuestions = [...allQuestions, ...batch.questions];
    answerKey = {...answerKey, ...batch.answerKey};
  }

  return {
    title: `${worksheetData.gradeLevel} ${worksheetData.topic} Worksheet`,
    questions: allQuestions,
    answerKey: answerKey,
  };
};
