// components/FeaturesSection.js
import React from 'react';

const FeaturesSection = ({activeTab}) => {
  return (
    <div className="max-w-4xl mx-auto mb-16">
      <h2 className="text-3xl font-semibold mb-8 text-center mt-8">
        {activeTab === 'text' ? 'Explore Our Free Printable Worksheets' : 'Create Beautiful Coloring Pages'}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {activeTab === 'text' ? (
          <>
            <div>
              <h3 className="text-xl font-semibold mb-3">Comprehensive Subject Coverage</h3>
              <p className="text-gray-600 mb-4">
                Our free printable worksheets span a wide range of subjects, including math, science, language arts, and
                social studies.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-3">Grade-Appropriate Content</h3>
              <p className="text-gray-600 mb-4">
                From preschool to high school, our worksheets are tailored to meet the needs of students at every grade
                level.
              </p>
            </div>
          </>
        ) : (
          <>
            <div>
              <h3 className="text-xl font-semibold mb-3">Custom Coloring Pages</h3>
              <p className="text-gray-600 mb-4">
                Create unique coloring pages with your own descriptions. Perfect for any theme or subject.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-3">AI-Powered Generation</h3>
              <p className="text-gray-600 mb-4">
                Our advanced AI technology creates detailed, printable coloring pages in seconds.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FeaturesSection;
