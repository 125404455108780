/* In Between page content of articles */
import React, {useEffect} from 'react';

const GoogleAdSense = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('AdSense error:', e);
    }
  }, []);

  return (
    <div className="my-8">
      <ins className="adsbygoogle"
           style={{display: 'block', textAlign: 'center'}}
           data-ad-layout="in-article"
           data-ad-format="fluid"
           data-ad-client="ca-pub-5639040448942846"
           data-ad-slot="4454057466"></ins>
    </div>
  );
};

export default GoogleAdSense;
